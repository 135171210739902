<!--
 * @Author: lsw lsw_0524@163.com
 * @Date: 2024-01-19 10:08:26
 * @LastEditors: lsw lsw_0524@163.com
 * @LastEditTime: 2024-04-29 10:43:46
 * @FilePath: /MathematicalIntelligence/Users/luoshiwen/Desktop/project/yiyun_project/src/O2O/basics/serviceAgreement.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-main>
    <el-tabs v-model="activeName" type="card">
      <el-tab-pane label="用户服务协议" name="first"></el-tab-pane>
      <el-tab-pane label="注册隐私协议" name="second"></el-tab-pane>
      <el-tab-pane label="上门服务条款" name="third"></el-tab-pane>
    </el-tabs>
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="160px" class="demo-ruleForm">
      <el-form-item label="用户服务协议：" v-if="activeName === 'first'">
        <template>
          <RichText :richTxt="ruleForm.user_service_agreement" @soninfo="val => (ruleForm.user_service_agreement = val)"></RichText>
        </template>
      </el-form-item>
      <el-form-item label="注册隐私协议：" v-if="activeName === 'second'">
        <template>
          <RichText :richTxt="ruleForm.register_agreement" @soninfo="val => (ruleForm.register_agreement = val)"></RichText>
        </template>
      </el-form-item>
      <el-form-item label="上门服务条款：" v-if="activeName === 'third'">
        <template>
          <RichText :richTxt="ruleForm.visit_service_agreement" @soninfo="val => (ruleForm.visit_service_agreement = val)"></RichText>
        </template>
      </el-form-item>
    </el-form>
    <Preservation @preservation="preservation"></Preservation>
  </el-main>
</template>

<script>
import RichText from '@/components/richText';
import Preservation from '@/components/preservation';
export default {
  components: {
    RichText,
    Preservation,
  },
  data() {
    return {
      activeName: 'first',
      ruleForm: {
        user_service_agreement: '',
        register_agreement: '',
        visit_service_agreement: '',
      },
      rules: {},
    };
  },
  created() {
    this.getService();
  },
  methods: {
    getService() {
      this.$axios.post(this.$api.O2O.basics.getBasicsSet).then(res => {
        if (res.code == 0) {
          this.ruleForm = {
            user_service_agreement: res.result.user_service_agreement,
            register_agreement: res.result.register_agreement,
            visit_service_agreement: res.result.visit_service_agreement,
          };
        } else {
          this.$message.error(res.msg);
        }
      });
    },
    preservation() {
      this.$axios.post(this.$api.O2O.basics.editAgreement, this.ruleForm).then(res => {
        if (res.code == 0) {
          this.$message.success('协议及条款保存成功');
        } else {
          this.$message.error(res.msg);
        }
      });
    },
  },
};
</script>

<style lang="less" scoped>
.el-main {
  background-color: #fff;
  min-height: 100%;
  padding-bottom: 60px;
}
</style>
